import i18n from "@/plugins/i18n";
import { colors } from "vuetify/lib";

export default {
  DaysOfWeek: {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  },

  AlertType: {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
  },

  NodeTypes: {
    LORAWAN: 0,
    API: 1,
    MQTT: 2,
    NODERED: 3,
    SPARKPLUG: 4,
    SPOTIO: 5,
    MODBUS: 6,
    OPC: 7,
  },

  Priority: {
    A: 0,
    B: 1,
    C: 2,
  },

  LimitType: {
    LOW: 0,
    HIGH: 1,
  },

  ContactType: {
    SMS: 0,
    EMAIL: 1,
    BOTH: 2,
  },

  ModuleType: {
    CHART: 0,
    GAUGE: 1,
    TEXT: 2,
    IMAGE: 3,
    MAP: 4,
    COMPANYINFO: 5,
    IFRAME: 6,
    WEATHER: 7,
    SINGLE: 8,
    RAWTABLE: 9,
    MULTICHART: 10,
    IMAGEMAP: 11,
    FUNCTIONBUTTON: 12,
    INFOCARD: 13,
    TRENDCHART: 14,
    SENSOROVERVIEW: 15,
    TILTCHART: 16,
    FILLINDICATOR: 17,
    CONTROLSLIDERS: 18,
    CAMONLINE: 19,
  },

  LogType: [
    { type: "SMS", label: i18n.t("log.type.sms"), color: colors.blue.accent1 },
    {
      type: "APP",
      label: i18n.t("log.type.app"),
      color: colors.green.accent3,
    },
    {
      type: "WARNING",
      label: i18n.t("log.type.warning"),
      color: colors.yellow.accent1,
    },
    {
      type: "ERROR",
      label: i18n.t("log.type.error"),
      color: colors.red.accent1,
    },
    {
      type: "USEREVENT",
      label: i18n.t("log.type.userevent"),
      color: colors.green.accent1,
    },
    {
      type: "LOGIN",
      label: i18n.t("log.type.login"),
      color: colors.blue.accent1,
    },
  ],

  CompanySettingFieldType: {
    STRING: 0,
    NUMBER: 1,
    DATE: 2,
    SELECT: 3,
    CHECKBOX: 4,
    PASSWORD: 5,
    NONE: 6,
    NODE_TABLE_COLUMNS: 7,
    COORDINATE: 8,
    MAP_SETTINGS: 9,
  },

  EventHubType: {
    DATA: 0,
    ENDPOINT: 1,
    ATTRIBUTE: 2,
  },

  FunctionTypes: {
    SCRIPT: 0,
    VALUEINPUT: 1,
    DASHBOARD: 2,
    DOWNLINK: 3,
    ONOFFSWITCH: 4,
  },

  ConditionTypes: {
    SENSORVALUE: {
      value: 0,
      text: i18n.t("conditionTree.types.0"),
      icon: "mdi-thermometer",
    },
    TEXT: { value: 1, text: i18n.t("conditionTree.types.1"), icon: "mdi-text" },
    NUMBER: {
      value: 2,
      text: i18n.t("conditionTree.types.2"),
      icon: "mdi-counter",
    },
    DATE: {
      value: 3,
      text: i18n.t("conditionTree.types.3"),
      icon: "mdi-calendar-range",
    },
    EMPTY: {
      value: 4,
      text: i18n.t("conditionTree.types.4"),
      icon: "mdi-circle-off-outline",
    },
  },

  DashboardTypes: DashboardTypes,
};

export const DashboardTypes = {
  DEFAULT: 0,
  MAP: 1,
  IMAGEMAP: 2,
};

export const IntegrationTypes = {
  CHARGE_AMPS: 0,
  CHIRPSTACK: 1,
};

export const IntegrationValueTypes = {
  STRING: 0,
  JSON: 1,
};

export const ChirpStackVersions = {
  V3: 0,
  V4: 1,
};

export const EventTypes = {
  DATA: 0,
  ENDPOINT: 1,
  ATTRIBUTE: 2,
  CHIRPSTACK: 3,
};

export const ChirpStackMessageType = {
  Gateway_V3: 0,
  Gateway_V4: 1,
  Device_V3: 2,
  Device_V4: 3,
};
